import React, { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Wrapper from './modal.style';
import theme from '../../theme';

const Modal = ({
                 children,
                 show,
                 setShow,
                 closeable = true,
                 closeOverlay = true,
                 wide,
                 modalItemClose,
                 fullWidth,
                 setShowTipModal,
               }) => {
  const ref = useRef();
  const [mounted, setMounted] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const modalContentRef = useRef(null);

  let originElement;

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      document.body.classList.remove(theme.classNames.hasModal);
      setShowTipModal && setShowTipModal(false);
      setShow(false);
      if (typeof originElement !== 'undefined') originElement.focus();
    }, 300);
  };

  const handleOverlayClick = () => {
    if (closeable && closeOverlay) closeModal();
  };

  const modalContentClass = wide ? 'modal__content-wide' : 'modal__content';
  const modalContentClose = modalItemClose
    ? 'modal__close__item-modal-close modal__close'
    : 'modal__close';

  useEffect(() => {
    ref.current = document.body;
    setMounted(true);

    originElement = document.activeElement;

    const handleKeyDown = e => {
      const { keyCode } = e;
      if (keyCode === 27 && closeable) closeModal();
      if (keyCode === 9) {
        const focusableModalElements = modalContentRef.current.querySelectorAll(
          'a[href], button:not([tabindex="-1"]), textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, li[tabindex]',
        );
        const firstFocusableElement = focusableModalElements[0];
        const lastFocusableElement = focusableModalElements[focusableModalElements.length - 1];
        if (!e.shiftKey && document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          return e.preventDefault();
        }
        if (e.shiftKey && document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      }
    };

    if (show) {
      document.body.classList.add(theme.classNames.hasModal);
      window.addEventListener('keydown', handleKeyDown, false);
      setTimeout(() => {
        if (modalContentRef.current) modalContentRef.current.focus();
      }, 100);
    } else {
      document.body.classList.remove(theme.classNames.hasModal);
    }
    return function cleanup() {
      window.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [show]);

  const content = show && (
    <Wrapper
      id="modal"
      onClick={() => handleOverlayClick()}
      className={isClosing ? 'isClosing' : ''}
      fullWidth={fullWidth}
    >
      <div className="modal-overlay">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div className="modal" role="dialog" onClick={e => e.stopPropagation()}>
          {closeable && (
            <button type="button" className={modalContentClose} onClick={() => closeModal()}>
              Close
            </button>
          )}
          <div className={modalContentClass} ref={modalContentRef} tabIndex="0">
            <div className="modal__body">{children}</div>
          </div>
        </div>
      </div>
    </Wrapper>
  );

  return mounted ? createPortal(content, ref.current) : null;
};

export default Modal;
