import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import HeadHelmet from '../components/head';
import { Inner, GridColumn, GridRow } from '../components/ui-elements/Grid';
import Modal from '../components/modal';
import styled from 'styled-components';
import NewsCards from '../components/pages/news/newsCards';
import ContactDiv from '../components/contactUs';
import initIContactScript from '../utils/initIContactScript';

const Intro = styled.div`
  @media (min-width: 0) {
    h1 {
      color: var(--base-color);
      font-weight: bold;
      text-align: left;
      margin-bottom: 0;
    }
    img {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      border-radius: 22px;
    }
    button {
      border: none;
      background-color: #fff;
      box-shadow: 0 2px 10px #002f6c33;
      border-radius: 22px;
      padding: 0.75rem 3rem;
      font-size: 16px;
      font-weight: bold;
      color: var(--base-color);
      margin: 2rem 0;
    }
  }
  @media (min-width: 500px) {
    img {
      width: 75%;
    }
  }
  @media (min-width: 960px) {
    font-size: 44px;
    padding: 2rem 0;
    h1 {
      font-size: 50px;
    }
    img {
      width: 100%;
    }
  }
`;

const ModalContent = styled.div`
  @media (min-width: 0) {
    img {
      width: auto;
      height: 150px;
      border-radius: 22px;
      object-fit: cover;
    }
    h3 {
      color: var(--base-color);
      text-align: left;
    }
  }
  @media (min-width: 960px) {
    display: flex;
    img {
      width: 100%;
      height: auto;
    }
    .child_1_desktop {
      margin: 0 2rem;
    }
    .child_2_desktop {
      width: 100%;
      margin: 0 2rem;
    }
  }
`;

const ContentWrapper = styled.div`
  margin-bottom: 2rem;
  p,
  li {
    text-align: left;
    font-size: 18px;
    color: #707070;
  }
  h2, h3, h4, h5 {
    text-align: left;
    font-size: 22px;
    color: var(--base-color);
  }
`;

const RelatedArticleWrapper = styled.div`
  padding: 1rem 0;
  h3 {
    font-size: 40px;
  }
`;

const DownloadModal = ({ setShow, show, title, image, guide_pdf }) => {
  useEffect(() => {
    if (show) {
      initIContactScript();
    }
  }, [show]);
  const pdfDownload = (e) => {
    if(guide_pdf?.publicURL) {
      let link = document.createElement('a');
      link.href = guide_pdf.publicURL;
      link.download = guide_pdf.publicURL;
      link.dispatchEvent(new MouseEvent('click'));
    }
    // submit form after the guide is downloaded
    e.target.submit();
  }
  return (
    <Modal show={show} setShow={setShow} wide>
      <ModalContent>
        <div className={'child_1_desktop'}>
          <h3>{title}</h3>
          <img src={image.childImageSharp.fluid.src} alt="" />
        </div>
        <div className={'child_2_desktop'}>
          <form
            className={'Contact__form'}
            method="POST"
            id="ic_signupform"
            captcha-key="6LeCZCcUAAAAALhxcQ5fN80W6Wa2K3GqRQK6WRjA"
            captcha-theme="light"
            new-captcha="true"
            action="https://app.icontact.com/icp/core/mycontacts/signup/designer/form/?id=665&cid=1791092&lid=33433"
            onSubmit={(e) => {
              e.preventDefault();
              pdfDownload(e);
            }}
          >
            <div
              data-validation-type="1"
              data-label="First Name"
              style={{ display: 'block', width: '100%' }}
            >
              <label>
                First Name*
                <input type="text" name="data[fname]" required />
              </label>{' '}
            </div>
            <div
              data-validation-type="1"
              data-label="Last Name"
              style={{ display: 'block', width: '100%' }}
            >
              <label>
                Last Name*
                <input type="text"  name="data[lname]" required />
              </label>{' '}
            </div>
            <div
              data-validation-type="1"
              data-label="Company Name"
              style={{ display: 'block', width: '100%' }}
            >
              <label>
                Company Name
                <input type="text" name="data[business]" />
              </label>{' '}
            </div>
            <div
              data-validation-type="1"
              data-label="Email"
              style={{ display: 'block', width: '100%' }}
            >
              <label>
                Email* <br />
                <input type="email" name="data[email]" required />
              </label>{' '}
            </div>
            <div
              data-validation-type="1"
              data-label="Phone Number"
              style={{ display: 'block', width: '100%' }}
            >
              <label>
                Phone Number*
                <input type="number" name="data[phone]" />
              </label>{' '}
            </div>
            <div
              dataname="listGroups"
              data-validation-type="1"
              data-label="Lists"
              style={{ display: 'none', width: '100%' }}
            >
              <h3>Lists<span className="indicator required">*</span></h3>
              <div className="option-container">
                <label className="checkbox">
                  <input
                    type="checkbox" alt=""
                    name="data[listGroups][]"
                    value="93324"
                    checked
                  />Newsletter Sign Ups
                </label>
              </div>
            </div>
            <div className="submit-container">
              <button className={'Services__blueButton'} type="submit">
                DOWNLOAD
              </button>
            </div>
          </form>
          <img src="//app.icontact.com/icp/core/signup/tracking.gif?id=665&cid=1791092&lid=33433" alt="" style={{width: '1px'}} />
        </div>
      </ModalContent>
    </Modal>
  );
};

const AllResourcesSlug = ({data, pageContext, location }) => {
  const { MetaData, title, description, image, guide_pdf } = data.strapiSsResourceGuides;
  // const { limit, numPagesGuides, numOfGuides, currentPage } = pageContext;

  const [showDownloadModal, setShowDownloadModal] = useState(false);
  return (
    <>
      <HeadHelmet data={MetaData} />
      <Layout>
        <Inner>
          <Intro>
            <GridRow>
              <GridColumn>
                <h1>{title}</h1>
                <button onClick={() => setShowDownloadModal(true)}>Download</button>
              </GridColumn>
              <GridColumn>
                <img src={image.childImageSharp.fluid.src} alt={''} />
              </GridColumn>
            </GridRow>
          </Intro>
          <ContentWrapper>
            <ReactMarkdown>{description}</ReactMarkdown>
          </ContentWrapper>
          <RelatedArticleWrapper>
            <h3>Related Articles</h3>
            <NewsCards queryData={data.allStrapiSsResourceGuides} hideViewAllButton location={location.pathname}/>
          </RelatedArticleWrapper>
        </Inner>
        <ContactDiv />
      </Layout>
      <DownloadModal
        show={showDownloadModal}
        setShow={setShowDownloadModal}
        title={title}
        image={image}
        guide_pdf={guide_pdf}
      />
    </>
  );
};

export default AllResourcesSlug;

export const query = graphql`
  query AllResourcesSlug($id: String!, $limit: Int!, $slug: String!) {
    strapiSsResourceGuides(id: { eq: $id }) {
      id
      MetaData {
        title
        metaKeywords
        metaDescription
      }
      title
      description
      slug
      guide_pdf {
        id
        publicURL
      }
      image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allStrapiSsResourceGuides(
      filter: {slug: {ne: $slug}}
      sort: { fields: [published], order: DESC }
      limit: $limit,
    ) {
      edges {
        node {
          id
          title
          slug
          featured_guide
          description
          published
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
