import styled from 'styled-components';
import theme from '../../theme';
import close from '../../../static/images/close.svg';
import closeMobile from '../../../static/images/close_btn_mobile.svg';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  animation: fadeIn 0.3s ease;

  &.isClosing {
    animation: fadeOut 0.3s ease;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .modal {
    position: relative;
    margin: auto;

    &-overlay {
      display: flex;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      overflow-y: auto;
      justify-content: center;
      align-items: center;
      padding: ${theme.spacingL};

      @media only screen and (max-width: ${theme.breakpoints.tabletPortrait}) {
        display: block;
        padding: 0;
        background: #fff;
      }
    }

    &__close {
      position: absolute;
      top: 0;
      right: -32px;
      padding: 0;
      height: 22px;
      width: 22px;
      border: none;
      text-indent: -8000px;
      overflow: hidden;
      background: url(${close}) no-repeat 50% 50%;
      background-size: 90%;
      cursor: pointer;
      z-index: 2;

      @media only screen and (max-width: ${theme.breakpoints.tabletPortrait}) {
        background: url(${closeMobile}) no-repeat 50% 50%;
        height: 24px;
        width: 24px;
        top: ${theme.spacingBase};
        right: ${theme.spacingS};
      }
    }
    &__close__item-modal-close {
      @media only screen and (max-width: ${theme.breakpoints.tabletPortrait}) {
        background-color: #fff;
        padding: 0.5em;
        border-radius: 50%;
      }
    }

    &__content {
      background: #fff;
      border-radius: ${theme.borderRadiusBase};
      box-shadow: ${theme.boxShadowBase};
      max-width: 800px;
      border: 1px solid ${theme.colors.silver};
      position: relative;
      overflow: ${props => (props.fullWidth ? 'hidden' : 'unset')};

      @media only screen and (max-width: ${theme.breakpoints.tabletPortrait}) {
        border-radius: 0;
        box-shadow: none;
        border: none;
        width: 100%;
      }
    }

    &__content-wide {
      background: #fff;
      border-radius: ${theme.borderRadiusBase};
      box-shadow: ${theme.boxShadowBase};
      border: 1px solid ${theme.colors.silver};
      position: relative;
      outline-style: none;
      @media only screen and (max-width: ${theme.breakpoints.tabletPortrait}) {
        border-radius: 0;
        box-shadow: none;
        border: none;
        width: 100%;
      }
      @media only screen and (min-width: ${theme.breakpoints.tabletLandscape}) {
        max-width: 100%;
        width: 825px;
      }
    }

    &__body {
      padding: ${props => (props.fullWidth ? 0 : theme.spacingBase)};
      p:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default Wrapper;
